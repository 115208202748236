// Utils
import jsonLogic from 'json-logic-js';
import hash from 'object-hash';
import { isNull } from 'lodash';

/**
 * Evaluates whether the given conditions are met based on the provided context.
 *
 * @param {Object} conditions - The JSONlogic conditions to evaluate.
 * @param {Object} context - The context in which to evaluate the conditions.
 *
 * @returns {boolean} - Returns true if the conditions are met, otherwise false.
 */
export function areConditionsMet(conditions, context) {
    return jsonLogic.apply(conditions, context);
}

/**
 * Recursively removes all undefined values from the given object
 *
 * @param {object|null} [object={}] the object to clean
 *
 * @returns {object} the object without undefined values
 */
export function clean(object = {}) {
    if (typeof object === 'object' && object !== null && !Array.isArray(object)) {
        Object.keys(object).forEach(k => {
            if (typeof object[k] === 'undefined') {
                delete object[k];
            }

            if (typeof object[k] === 'object') {
                object[k] = clean(object[k]);
            }
        });

        return object;
    }

    return {};
}

/**
 * Generate a hash from any object or type. Defaults to sha1 with hex encoding.
 *
 * @param {*} obj - The object to hash.
 * @param {Object} [options] - The options to pass to the hash
 * @param {string} [options.algorithm='sha1'] hash algo to be used: 'sha1', 'md5', 'passthrough'.
 *  - This supports the algorithms returned by crypto.getHashes(). Note that the default of SHA-1 is not considered secure, and a stronger algorithm should be used if a cryptographical hash is desired.
 *  - This also supports the passthrough algorith, which will return the information that would otherwise have been hashed.
 * @param {boolean} [options.excludeValues=false] hash object keys, values ignored
 * @param {'buffer'|'hex'|'binary'|'base64'} [options.encoding='hex'] hash encoding.
 * @param {boolean} [options.ignoreUnknown=false] ignore unknown object types
 * @param {function} [options.replacer] optional function that replaces values before hashing. default: accept all values
 * @param {boolean} [options.respectFunctionProperties=true] Whether properties on functions are considered when hashing.
 * @param {boolean} [options.respectFunctionNames=true] consider name property of functions for hashing
 * @param {boolean} [options.respectType=true] Whether special type attributes (.prototype, .__proto__, .constructor) are hashed
 * @param {boolean} [options.unorderedArrays=false] Sort all arrays before hashing. Note that this affects all collections, i.e. including typed arrays, Sets, Maps, etc.
 * @param {boolean} [options.unorderedSets=true] Sort Set and Map instances before hashing, i.e. make hash(new Set([1, 2])) == hash(new Set([2, 1])) return true.
 * @param {boolean} [options.unorderedObjects=true] Sort objects before hashing, i.e. make hash({ x: 1, y: 2 }) === hash({ y: 2, x: 1 }).
 * @param {function} [options.excludeKeys] optional function for excluding specific key(s) from hashing, if true is returned then
 *
 * @returns {string} - The hash of the object.
 */
export function hashObject(obj, options) {
    return hash(obj, options);
}

/**
 * Deeply extends an object with properties from additional objects.
 *
 * This function recursively merges properties from source objects into the target object.
 * It handles various data types including arrays, objects, strings, dates, and functions.
 *
 * @param {Object} obj - The target object to extend.
 * @param {...Object} others - One or more source objects to extend the target object with.
 *
 * @returns {Object} - The extended target object.
 */
export function deepExtend(obj, ...others) {
    return deepExt(obj, false, ...others);
}

/**
 * Deeply extends an object by merging properties from additional source objects.
 * If a property value contains the pattern "#{ _ }", it will be replaced with the corresponding value from the target object.
 *
 * @param {Object} obj - The target object to extend.
 * @param {...Object} others - One or more source objects containing properties to merge into the target object.
 * @returns {Object} The extended target object.
 */
export function deepExtendConcat(obj, ...others) {
    return deepExt(obj, true, ...others);
}

/**
 * Deeply extends an object with properties from additional source objects.
 *
 * @param {Object} obj - The target object to extend.
 * @param {boolean} concatArrays - Whether to concatenate arrays instead of replacing them.
 * @param {...Object} others - The source objects to extend the target object with.
 *
 * @returns {Object} The extended target object.
 *
 * @private
 */
function deepExt(obj, concatArrays, ...others) {
    const parentRE = /#{\s*?_\s*?}/;

    others.forEach(source => {
        Object.keys(source ?? {}).forEach(prop => {
            obj[prop] = mergeProperty(obj[prop], source[prop], parentRE, concatArrays);
        });
    });

    return obj;
}

/**
 * Merges a property from a source object into a target object property based on specific conditions.
 *
 * @param {any} objProp - The property from the target object.
 * @param {any} sourceProp - The property from the source object.
 * @param {RegExp} parentRE - A regular expression used to test and replace strings.
 * @param {boolean} [concatArrays=false] - A flag to indicate whether to concatenate arrays.
 *
 * @returns {any} - The merged property.
 *
 * @private
 */
function mergeProperty(objProp, sourceProp, parentRE, concatArrays = false) {
    if (sourceProp === undefined || typeof objProp === 'function' || sourceProp === null || sourceProp instanceof Date) {
        return sourceProp;
    } else if (typeof sourceProp === 'string' && parentRE.test(sourceProp)) {
        return typeof objProp === 'string' ? sourceProp.replace(parentRE, objProp) : sourceProp;
    } else if (Array.isArray(sourceProp) || Array.isArray(objProp)) {
        return mergeArrays(objProp, sourceProp, concatArrays);
    } else if (typeof sourceProp === 'object' || typeof objProp === 'object') {
        return mergeObjects(objProp, sourceProp, concatArrays);
    } else {
        return sourceProp;
    }
}

/**
 * Merges two properties, which can be arrays, into one.
 *
 * @param {Array|any} objProp - The target property which will be merged with the source property.
 * @param {Array|any} sourceProp - The source property to merge into the target property.
 * @param {boolean} [concatArrays=false] - A flag to indicate whether to concatenate arrays.
 *
 * @returns {Array|any} - The merged result. If both properties are arrays, it returns a unique array.
 *                        If only the source property is an array, it returns a unique array.
 *                        Otherwise, it returns the source property.
 * @private
 */
function mergeArrays(objProp, sourceProp, concatArrays = false) {
    if (concatArrays && Array.isArray(sourceProp)) {
        if (!Array.isArray(objProp)) {
            objProp = [];
        }

        const set = new Set(objProp);
        sourceProp.forEach(item => set.add(item));
        objProp = Array.from(set);
        return objProp;

    } else if (Array.isArray(sourceProp) && Array.isArray(objProp)) {
        return deepExt(objProp, concatArrays, sourceProp).filter(item => !isNull(item));
    } else {
        return sourceProp;
    }
}

/**
 * Merges two objects. If both properties are objects, it performs a deep merge.
 * Otherwise, it returns the source property.
 *
 * @param {Object} objProp - The target object property.
 * @param {Object} sourceProp - The source object property.
 * @param {boolean} [concatArrays=false] - A flag to indicate whether to concatenate arrays.
 * @returns {Object} - The merged object or the source property.
 *
 * @private
 */
function mergeObjects(objProp, sourceProp, concatArrays = false) {
    return typeof sourceProp === 'object' && typeof objProp === 'object'
        ? deepExt({ ...objProp }, concatArrays, sourceProp)
        : sourceProp;
}

// Global declaration to make it available for https://github.com/Spotme/pkg-forms/blob/dev/src/bstg-source/bstg-results/fb-state.js#L106
// Read only to make it no overiden by pkg-person old code
// The if is needed because htis seems to be initialized twice locally.
if (typeof window !== 'undefined' && !window.deepExtend) {
    Object.defineProperty(window, 'deepExtend', {
        value: deepExtend,
        writable: false
    });
}
